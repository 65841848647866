import styled from "styled-components";
import Header from "./Header";

// 나중에 신고된 피드로 넘어가요.

const ComplainPost = () => {
  return (
    <div>
      <Header />
    </div>
  );
};

export default ComplainPost;
