import styled from "styled-components";
import Header from "./Header";

// 나중에 세부 문의 내역으로 가요

const AskPost = () => {
  return (
    <div>
      <Header />
    </div>
  );
};

export default AskPost;
